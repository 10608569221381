import orderBy from 'lodash/orderBy';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import * as nearAPI from 'near-api-js';

// @mui
import { Container, Stack, Typography, Button, Box, Modal, Tab, Tabs, LinearProgress } from '@mui/material';

// Components
import { magic } from '../magic';
import Loading from './Loading';
import { ProductSort, PetList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/pets';
import Iconify from '../components/iconify';
import Label from '../components/label';

import EmptyContent from '../components/empty-content';
import UserNewEditForm from '../sections/@dashboard/user/PetNewEditForm';
import { getShelterAccountId } from './_mock';

let near;

const defaultFilters = {
  tab: 'dog',
  status: 'adoptable',
  petType: 'dog',
};

export default function PetsPage() {
  // State initialization
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [userMetadata, setUserMetadata] = useState(null);
  const [shelterAccountId, setShelterAccountId] = useState(null);
  const navigate = useNavigate();
  const networkId = 'testnet'; // testnet, betanet, or mainnet
  const [open, setOpen] = useState(false);

  // State for Near API
  const [nearBalance, setNearBalance] = useState(0);
  const [userPets, setUserPets] = useState([]);
  const [apiPets, setApiPets] = useState([]);
  const [ownerName, setOwnerName] = useState('');
  const [isApiLoading, setIsApiLoading] = useState(true);

  const [sortBy, setSortBy] = useState('latest');
  const [filters, setFilters] = useState(defaultFilters);
  const [isTabLoading, setIsTabLoading] = useState(false);


  const [filteredAndSortedPets, setFilteredAndSortedPets] = useState([]);
  // Define the callback function to update filteredAndSortedPets
  const updateFilteredAndSortedPets = (newPets) => {
    console.log("called updateFilteredAndSortedPets")
    setFilteredAndSortedPets(newPets);
    handleFilterPublish(null, 'adopted');
  };

  // const dataFiltered = applyFilter({
  //   inputData: apiPets,
  //   filters,
  //   sortBy,
  // });

  const handleFilterPublish = (event, newValue) => {
    setIsApiLoading(true);


    // Update the filter based on the selected tab
    if (newValue === 'adopted') {
      // Filter for adopted pets with "All" tab and "All" petType option
      setFilters({ ...filters, tab: 'adopted', status: 'adopted', petType: 'all' });
    } else if (newValue === 'cat') {
      // Filter for cats with "All" status option
      setFilters({ ...filters, tab: 'cat',  status: 'adoptable', petType: 'cat' });
    } else if (newValue === 'dog') {
      // Filter for dogs with "All" status option
      setFilters({ ...filters, tab: 'dog', status: 'all', petType: 'dog' });
    } else {
      // For other tabs, use the original filter logic
      setFilters({ ...filters, tab: newValue, status: 'all', petType: 'all' });
    }
    setIsApiLoading(false);

  };
  

  const allowedAddresses = [
    'fb9b34e032a94707e114023c44698716bef222d36310b48c7af02e5240c2b612',
    '45bc1ecbfd50a5777f5c4dfe41e09e64d0cef8ab2930218d78cb4d00e4702bf1'
    // Add more addresses here if needed
  ];

  // Function to handle API errors
  const handleAPIError = (error, message) => {
    console.error(message, error);
    // Handle the error (e.g., show a message to the user)
  };

  // Modal open and close handlers
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (userMetadata) {
      fetchUserPetsAndBalance(userMetadata.publicAddress)
        .then(({ pets }) => setUserPets(pets))
        .catch((error) => console.error('Error fetching user pets:', error));
    }
  };  

  // Function to get Near API configuration
  const getNearConfig = () => {
    return {
      networkId,
      keyStore: new nearAPI.keyStores.BrowserLocalStorageKeyStore(),
      nodeUrl: `https://rpc.${networkId}.near.org`,
      walletUrl: `https://wallet.${networkId}.near.org`,
      helperUrl: `https://helper.${networkId}.near.org`,
      explorerUrl: `https://explorer.${networkId}.near.org`,
    };
  };

  // Function to create Near API instance
  const createNearInstance = async () => {
    const config = getNearConfig();
    near = await nearAPI.connect(config);
  };

  // Function to fetch user pets and balance
  const fetchUserPetsAndBalance = async (userPublicAddress) => {
    try {
      const provider = new nearAPI.providers.JsonRpcProvider(`https://rpc.${networkId}.near.org`);
      const jsonstring = JSON.stringify({ account_id: userPublicAddress });
      const encodedData = window.btoa(jsonstring);

      const [rawResult, account] = await Promise.all([
        provider.query({
          request_type: 'call_function',
          account_id: 'ilovepets-m2.testnet',
          method_name: 'ppp_tokens_for_owner',
          args_base64: encodedData,
          finality: 'optimistic',
        }),
        near.account(userPublicAddress),
      ]);

      const encodedResult = new Uint8Array(rawResult.result);
      const decoder = new TextDecoder();
      const decodedResult = decoder.decode(encodedResult);
      const pets = JSON.parse(decodedResult);

      const bal = await account.getAccountBalance();
      const balance = nearAPI.utils.format.formatNearAmount(bal.total);

      return { pets, balance };
    } catch (error) {
      handleAPIError(error, 'Error fetching user data:');
      return { pets: [], balance: null };
    }
  };

  // Function to fetch user data
  const fetchUserData = async () => {
    setIsLoading(true);

    console.log('Call fetchUserData');

    // Check if the user is logged in using 'magic' (replace with actual authentication logic)
    const magicIsLoggedIn = true; // Replace with actual authentication check

    if (magicIsLoggedIn) {
      try {
        const user = await magic.user.getMetadata();
        setUserMetadata(user);
        const { pets, balance } = await fetchUserPetsAndBalance(user.publicAddress);
        setUserPets(pets);
        setNearBalance(balance);
        setShelterAccountId(getShelterAccountId(user.publicAddress));
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user metadata:', error);
      }
    } else {
      // If no user is logged in, redirect to `/login`
      navigate('/login', { replace: true });
    }
  };

  // useEffect to initialize Near and fetch user data
  useEffect(() => {
    createNearInstance();
    fetchUserData();
  }, [navigate, networkId]);



  useEffect(() => {
    // Define your API endpoint here

    setIsApiLoading(true);

    if(userMetadata) {
    // const shelterAccountId = getShelterAccountId(userMetadata.publicAddress);

    const apiUrl = `https://uot4ttu72a.execute-api.us-east-1.amazonaws.com/default/getPetsByAccountId?account_id=${shelterAccountId}`;
  
    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Assuming the API response contains an array of pets
        setApiPets(data.pets); // Update the state with the fetched data
        setOwnerName(data.shelter_name_common);
        setIsApiLoading(false); // Set loading to false after data is fetched
  
        // Apply filtering and sorting logic to apiPets and store the result in filteredAndSortedPets
        const filteredAndSortedData = applyFilter({
          inputData: data.pets, // Use the fetched data
          filters,
          sortBy,
        });
        setFilteredAndSortedPets(filteredAndSortedData);
        setIsApiLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching user pets:', error);
        setIsApiLoading(false); // Set loading to false in case of an error
      });
    }
  }, [shelterAccountId, filters, sortBy]); 

  return userMetadata ? (
    <>
      <Helmet>
        <title> Pets | Petastic </title>
      </Helmet>

      {/* User Edit Form Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{
          style: {
            background: 'url(/assets/background/gradient-glow.32c37d10.svg)',
          },
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 'none',
          }}
        >
          <Container>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                minWidth: '50vw',
              }}
            >
              <UserNewEditForm handleClose={handleClose} sx={{ alignItems: 'center', justifyContent: 'center' }} />
            </Box>
          </Container>
        </Box>
      </Modal>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3} sx={{ mt: -1 }}>
          <Typography variant="h4" gutterBottom>
          {ownerName} 
          </Typography>
          {userPets.length > 0 && (
            <Button onClick={handleOpen} variant="contained" sx={{ boxShadow: 'none' }}>
              Add Pet
            </Button>
          )}
        </Stack>

        <Box borderBottom="1px solid #CED4DA" width="100%" mt={1} />

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 5 }}
        >
          {/* You can add additional components or UI elements here */}
        </Stack>

        {!isApiLoading && apiPets.length === 0 ? (
          <EmptyContent
            title="Welcome to the future of pet care."
            description="Let's add your furry friends."
            isEmptyWallet={nearBalance === 0}
            handleClose={handleClose}
          />
        ) : (
          <>
            <Tabs
              value={filters.tab}
              onChange={handleFilterPublish}
              sx={{
                mb: { xs: 3, md: 5 },
              }}
            >
                    {/* Conditionally render the Linear Progress Bar */}
      {isApiLoading  && (
        <LinearProgress
          color="primary" // Choose the color you want
          sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
        />
      )}
              <Tab
                key="dog"
                iconPosition="end"
                value="dog"
                label="Dogs"
                icon={
                  <Label
                    variant={(filters.tab === 'dog' && 'filled') || 'soft'}
                    color={(filters.tab === 'dog' && 'info') || 'default'}
                  >
                    {apiPets.filter((pet) => pet.type.includes('Dog')).length}
                  </Label>
                }
                sx={{ textTransform: 'capitalize' }}
              />
              <Tab
                key="cat"
                iconPosition="end"
                value="cat"
                label="Cats"
                icon={
                  <Label
                    variant={(filters.tab === 'cat' && 'filled') || 'soft'}
                    color={(filters.tab === 'cat' && 'info') || 'default'}
                  >
                    {apiPets.filter((pet) => pet.type.includes('Cat')).length}
                  </Label>
                }
                sx={{ textTransform: 'capitalize' }}
              />
              <Tab
                key="adopted"
                iconPosition="end"
                value="adopted"
                label="Adopted"
                icon={
                  <Label
                    variant={(filters.tab === 'adopted' && 'filled') || 'soft'}
                    color={(filters.tab === 'adopted' && 'info') || 'default'}
                  >
                    {apiPets.filter((pet) => pet.status === 'adopted').length}
                  </Label>
                }
                sx={{ textTransform: 'capitalize' }}
              />
            </Tabs>
            {shelterAccountId ? (
              <PetList
                user={userMetadata}
                apiPets={filteredAndSortedPets} // Pass filteredAndSortedPets as a prop
                updateFilteredAndSortedPets={updateFilteredAndSortedPets} // Pass the callback function as a prop
              />
            ) : (
              <PetList user={userMetadata} apiPets={userPets} />
            )}
          </>
        )}
      </Container>
    </>
  ) : (
    <Loading />
  );
}

// ----------------------------------------------------------------------

const applyFilter = ({ inputData, filters, sortBy }) => {
  const { tab, petType, status } = filters; // Add a status property to your filters

  console.log('inputData:', inputData);
  console.log('petType:', petType);

  let filteredPets = [...inputData]; // Create a copy of the inputData

  // Add filtering by petType
  if (petType === 'dog' ) {
    filteredPets = filteredPets.filter((pet) => pet.type.includes('Dog') && pet.status.includes('adoptable'));
  } 
  
  if (petType === 'cat') {
    filteredPets = filteredPets.filter((pet) => pet.type.includes('Cat')&& pet.status.includes('adoptable'));
  }

  // Filter by status
  if (status === 'adopted') {
    filteredPets = filteredPets.filter((pet) => pet.status === 'adopted');
  }

  console.log('Filtered Pets:', filteredPets);

  return filteredPets;
};
