import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Typography,
  Stack,
  Button,
  Switch,
  Avatar,
  ListItemText,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  IconButton,
  Grid,
  FormControlLabel,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import LockIcon from '@mui/icons-material/Lock';
import emailjs from '@emailjs/browser';

// routes
import { paths } from '../../../routes/paths';
import { useRouter } from '../../../routes/hooks';

import Logo from '../../../components/logo_near';
import { fCurrency } from '../../../utils/format-number';
import { bgBlur } from '../../../utils/cssStyles';

// Components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import Iconify from '../../../components/iconify';
import { handlePetAdoption, updatePetStatus, sendEmail } from './petastic-api';

import CustomPopover, { usePopover } from '../../../components/custom-popover';

import FormProvider, {
  RHFSwitch,
  RHFTextField,
  // RHFUploadAvatar,
  // RHFAutocomplete,
} from '../../../components/hook-form';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  ...bgBlur({ color: '#FFFFFF' }),
  position: 'relative',
  width: '100%',
  height: '100%',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.6s',
  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
  '&.flipped': {
    transform: 'rotateY(180deg)',
  },
}));

const StyledPetImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// Main PetCard component
export default function PetCard({ user, pet, filteredAndSortedPets, updateFilteredAndSortedPets }) {
  const popover = usePopover();

  const router = useRouter();

  // State
  const [txhash, setTxHash] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  // Destructure pet data
  const { pet_passport_id, metadata, pet_owner_id, status } = pet;
  const { title, description, media } = metadata;
  const { species, gender, breed, 'life-stage': lifeStage } = JSON.parse(description);
  const userID = user.publicAddress;

  const [petStatus, setPetStatus] = useState(status);
  const updatePetStatusCallback = (newStatus) => {
    setPetStatus(newStatus);
  };

  // Effect for fetching txhash
  useEffect(() => {
    const fetchTxHash = async (pet_passport_id) => {
      try {
        const transactionsMapString = localStorage.getItem('transactionMap');

        if (transactionsMapString) {
          const transactionsMap = JSON.parse(transactionsMapString);
          const txhash = transactionsMap[pet_passport_id];
          setTxHash(txhash || 'no');
        } else {
          setTxHash('no');
        }
      } catch (error) {
        console.error('Error retrieving txhash from local storage:', error);
        setTxHash('no');
      }
    };

    fetchTxHash(pet_passport_id);
  }, [pet]);

  // Render the component
  return (
    <StyledCard sx={{ textAlign: 'center' }}>
      {/* Image */}
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {title && (
          <Label
            variant="caption"
            color={(title === 'sale' && 'error') || 'info'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '2px',
              position: 'absolute',
              height: '40px',
              right: '16px',
              top: '16px',
              background: 'rgba(255, 255, 255, 0.8)',
              border: '1px solid #CED4DA',
              borderRadius: '8px',
              zIndex: 9,
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '14px',
              textAlign: 'center',
              letterSpacing: '0.01em',
              color: '#343A40',
              padding: '0 8px',
            }}
          >
            <Typography variant="caption" sx={{ fontSize: '8px' }}>
              REWARDS
            </Typography>
            <Typography variant="caption" sx={{ fontSize: '8px', fontWeight: 400 }}>
              100 KBL
            </Typography>
          </Label>
        )}
        {title && (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Label
              onClick={popover.onOpen}
              color={(status === 'adoptable' && 'info') || 'success'}
              endIcon={<Iconify icon="eva:more-vertical-fill" sx={{ height: '10px' }} />}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                position: 'absolute',
                top: '16px',
                zIndex: 9,
                ml: 2,
              }}
            >
              {petStatus}
            </Label>
          </Box>
        )}
        <StyledPetImg alt={title} src={media} />
      </Box>

      {/* Details */}
      <Box sx={{ mx: 3 }}>
        <Box sx={{ textAlign: 'left', pb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Typography variant="subtitle1" sx={{ mt: 2, mb: 0.5, color: '#2C4CFF' }}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {breed}
            </Typography>
          </div>
          <a
            href={`https://explorer.testnet.near.org/transactions/${txhash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo width={16} height={16} />
          </a>
        </Box>
        <Box sx={{ pb: 3, marginLeft: -3, marginRight: -3 }}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
            <div>
              <Typography variant="caption" component="div" sx={{ mb: 0.75, textTransform: 'uppercase' }}>
                Gender
              </Typography>
              <Typography variant="value">{gender}</Typography>
            </div>
            <div>
              <Typography variant="caption" component="div" sx={{ mb: 0.75, textTransform: 'uppercase' }}>
                Life Stage
              </Typography>
              <Typography variant="value">{lifeStage}</Typography>
            </div>
            <div>
              <Typography variant="caption" component="div" sx={{ mb: 0.75, textTransform: 'uppercase' }}>
                Weight
              </Typography>
              <LockIcon
                sx={{
                  fontSize: 16,
                  width: 16,
                }}
              />
            </div>
          </Box>
        </Box>
        <Box sx={{ marginBottom: 3 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 12px',
                border: '1px solid #64748B',
                borderRadius: 1,
                backgroundColor: 'transparent',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 12,
                lineHeight: '18px',
                textAlign: 'center',
                color: '#64748B',
                textDecoration: 'none',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LockIcon sx={{ marginRight: 1 }} />
                <Typography variant="body2">View Details</Typography>
              </Box>
            </Button>
          </div>

          <CustomPopover open={popover.open} onClose={popover.onClose} arrow="bottom-center" sx={{ width: 140 }}>
            <MenuItem
              onClick={() => {
                popover.onClose();
                // router.push(paths.dashboard.post.details(title));
                setIsConfirmDialogOpen(true);
              }}
            >
              <Iconify icon="solar:eye-bold" />
              Transfer
            </MenuItem>

            <MenuItem
              disabled
              onClick={() => {
                popover.onClose();
                setIsEditDialogOpen(true);
                // router.push(paths.dashboard.post.edit(title));
              }}
            >
              <Iconify icon="solar:pen-bold" />
              Edit
            </MenuItem>

            <MenuItem
              disabled
              onClick={() => {
                popover.onClose();
              }}
              sx={{ color: 'error.main' }}
            >
              <Iconify icon="solar:trash-bin-trash-bold" />
              Lock
            </MenuItem>
          </CustomPopover>

          {/* Render the ConfirmTransferDialog here */}
          <ConfirmTransferDialog
            open={isConfirmDialogOpen}
            onClose={() => setIsConfirmDialogOpen(false)}
            pet={pet}
            pet_owner_id={pet_owner_id} // Pass pet_owner_id
            pet_passport_id={pet_passport_id} // Pass pet_passport_id
            new_status="adoptable"
            callback={updatePetStatusCallback} // Pass the callback function as a prop
            filteredAndSortedPets={filteredAndSortedPets}
            updateFilteredAndSortedPets={updateFilteredAndSortedPets}
          />

          {/* Render the EditStatusialog */}
          <EditStatusialog
            open={isEditDialogOpen}
            onClose={() => setIsEditDialogOpen(false)}
            pet={pet}
            pet_owner_id={pet_owner_id} // Pass pet_owner_id
            pet_passport_id={pet_passport_id} // Pass pet_passport_id
            new_status="adoptable"
            callback={updatePetStatusCallback} // Pass the callback function as a prop
            filteredAndSortedPets={filteredAndSortedPets}
            updateFilteredAndSortedPets={updateFilteredAndSortedPets}
          />
        </Box>
      </Box>
    </StyledCard>
  );
}

// ConfirmTransferDialog component
function ConfirmTransferDialog({
  open,
  onClose,
  pet,
  _pet_owner_id,
  _pet_passport_id,
  new_status,
  callback,
  filteredAndSortedPets,
  updateFilteredAndSortedPets,
}) {
  const [page, setPage] = useState(1);


  // Destructure pet data
  const { pet_passport_id, metadata, pet_owner_id, status } = pet;
  const { title, description, media } = metadata;
  const { species, gender, breed, 'life-stage': lifeStage } = JSON.parse(description);
  // const userID = user.publicAddress;

  const NewUserSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phoneNumber: Yup.string().required('Phone number is required'),
    zipCode: Yup.string().required('Zip code is required'),
    // avatarUrl: Yup.mixed().nullable().required('Avatar is required'),
    // not required
    trial: Yup.string(),
    fallback: Yup.boolean(),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));

      console.log('data : ', data);

      // Prepare the request data including the new owner info
      const petPassportId = pet.pet_passport_id;
      const currentAccountId = pet.pet_owner_id;
      const newOwnerInfo = {
        owner_id: '5ee83180f121686526084263abc', // Replace with the actual owner ID
        issuer: null,
        trial_period: data.trial,
        public_address: null,
        wallet_type: 'magic',
        location_zip: data.zipCode,
        email: data.email,
        phone_number: data.phoneNumber,
      };

      // Make the API request to handle pet adoption
      const handlePetAdoptionResponse = await handlePetAdoption(petPassportId, currentAccountId, newOwnerInfo);

      if (handlePetAdoptionResponse.success) {
        // If the pet adoption is successful, update the pet status locally
        const updatedPets = filteredAndSortedPets.map((p) =>
          p.pet_passport_id === pet.pet_passport_id
            ? { ...p, status: 'adopted' } // Update the status for the transferred pet
            : p
        );

        // Send email using sendEmail function (replace with your service and template details)
        const conversationId = 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4';
        const petPassport = 'c888d40b4c847cd3cf67bdc6049a1b57';
        const userEmail = data.email;
        const emailResponse = await sendEmail(userEmail, conversationId, petPassport);

        if (!emailResponse.success) {
          //   // Handle email sending error here
          console.error('Email sending error:', emailResponse.message);
          return;
        }

        // Call the callback function to update filteredAndSortedPets
        updateFilteredAndSortedPets(updatedPets);

        // Close the dialog
        onClose();
      } else {
        // Handle pet adoption failure
        console.error('Pet adoption failed:', handlePetAdoptionResponse.message);
      }
    } catch (error) {
      // Handle any other errors here
      console.error('An error occurred:', error);
    }
  });

  // const onSubmit = handleSubmit(async (data) => {
  //   try {
  //     await new Promise((resolve) => setTimeout(resolve, 500));

  //     console.log(data);

  //     setNewCaretakerEmail(data.email);
  //     setNewCaretakerPhone(data.phoneNumber);
  //     setNewCaretakerZip(data.zipCode);
  //     setIsTrialPeriod(data.trial);
  //     setRemainFallbackCaretaker(data.fallback);
  //     // handleNextPage();
  //     handleConfirmTransfer();

  //     // reset();
  //     //     enqueueSnackbar(currentUser ? 'Update success!' : 'Create success!');
  //     //     router.push(paths.dashboard.user.list);
  //     //     console.info('DATA', data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // });

  // const handleNextPage = () => {
  //   setPage(page + 1);
  // };

  // const handleConfirmTransfer = async () => {
  //   try {
  //     // Prepare the request data including the new owner info
  //     const petPassportId = pet.pet_passport_id;
  //     const currentAccountId = pet.pet_owner_id;
  //     const newOwnerInfo = {
  //       owner_id: '5ee83180f121686526084263abc', // Replace with the actual owner ID
  //       issuer: null,
  //       trial_period: isTrialPeriod,
  //       public_address: null,
  //       wallet_type: 'magic',
  //       location_zip: newCaretakerZip,
  //       email: newCaretakerEmail,
  //       phone_number: newCaretakerPhone,
  //     };

  //     // Make the API request to handle pet adoption
  //     const handlePetAdoptionResponse = await handlePetAdoption(
  //       petPassportId,
  //       currentAccountId,
  //       newOwnerInfo
  //     );

  //     if (handlePetAdoptionResponse.success) {
  //       // If the pet adoption is successful, update the pet status locally
  //       const updatedPets = filteredAndSortedPets.map((p) =>
  //         p.pet_passport_id === pet.pet_passport_id
  //           ? { ...p, status: 'adopted' } // Update the status for the transferred pet
  //           : p
  //       );

  //       console.log("updatedPets: ", updatedPets);
  //       console.log("updateFilteredAndSortedPets", updateFilteredAndSortedPets);

  //       // Call the callback function to update filteredAndSortedPets
  //       updateFilteredAndSortedPets(updatedPets);

  // //     if (handlePetAdoptionResponse.success) {
  // //       // Call the callback function to update petStatus when updateStatusResponse is true
  // //       callback(new_status);
  // //       setPage(3);
  // //     }

  // //     // if (!updateStatusResponse.success) {
  // //     //   // Handle API request error here
  // //     //   console.error('API request error:', updateStatusResponse.message);
  // //     //   return;
  // //     // }

  // //     // // If the API request was successful, proceed to send the email
  // //     // // Send email using sendEmail function (replace with your service and template details)
  // //     // const userEmail = newCaretakerEmail; // Replace with the user's email address
  // //     // const emailResponse = await sendEmail(userEmail);

  // //     // if (!emailResponse.success) {
  // //     //   // Handle email sending error here
  // //     //   console.error('Email sending error:', emailResponse.message);
  // //     //   return;
  // //     // }

  // //     // console.log('Pet status updated and email sent successfully');
  // //     // setPage(3); // Move to the "All Done" page after sending the email

  //       // Move to the "All Done" page
  //       // setPage(3);
  //     } else {
  //       // Handle pet adoption failure
  //       console.error('Pet adoption failed:', handlePetAdoptionResponse.message);
  //     }
  //   } catch (error) {
  //     // Handle any other errors here
  //     console.error('An error occurred:', error);
  //   }
  // };

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle>{page === 1 ? 'Transfer guardianship ' : page === 2 ? 'Review' : 'All Done'}</DialogTitle>
      {page === 1 && (
        <Stack spacing={2}>
          <DialogContent sx={{ overflow: 'unset' }}>
            <Stack direction="column" spacing={2} sx={{ mb: 3 }}>
              <Typography style={{ fontWeight: 'bold' }}>I want to transfer:</Typography>
              <ListItemText
                primary={`${title} the ${species.split(':').pop()}`}
                secondary={` an ${breed}`}
                secondaryTypographyProps={{ component: 'span', mt: 0.5 }}
                sx={{ paddingLeft: '16px' }}
              />
            </Stack>
            <Stack spacing={3}>
              <Typography style={{ fontWeight: 'bold' }}>To the following human: </Typography>
              <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack spacing={3}>
                  <RHFTextField name="email" label="Email Address" />
                  <RHFTextField name="phoneNumber" label="Phone Number" />
                  <RHFTextField name="zipCode" label="Zip/Code" />
                  <RHFSwitch
                    name="trial"
                    labelPlacement="start"
                    label={
                      <>
                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                          Trial Period
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Enabling this feature will make the transfer of {title}'s guardianship temporary for 30 days.
                        </Typography>
                      </>
                    }
                    sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                  />
                  <RHFSwitch
                    name="fallback"
                    labelPlacement="start"
                    label={
                      <>
                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                          Fallback Transfer
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Remain as the fallback guardian in case the new guardian is unable to care for {title}.
                        </Typography>
                      </>
                    }
                    sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                  />
                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      Confirm Transfer
                    </LoadingButton>
                  </Stack>
                </Stack>
              </FormProvider>
            </Stack>
          </DialogContent>
        </Stack>
      )}
      <DialogActions>{page === 3 && <Button onClick={onClose}>Close</Button>}</DialogActions>
    </Dialog>
  );
}

// ConfirmTransferDialog component
function EditStatusialog({
  open,
  onClose,
  pet,
  _pet_owner_id,
  _pet_passport_id,
  new_status,
  callback,
  filteredAndSortedPets,
  updateFilteredAndSortedPets,
}) {
  const [page, setPage] = useState(1);
  const [newCaretakerEmail, setNewCaretakerEmail] = useState('');
  const [newCaretakerPhone, setNewCaretakerPhone] = useState('');
  const [newCaretakerZip, setNewCaretakerZip] = useState('');

  const [isTrialPeriod, setIsTrialPeriod] = useState(false);
  const [remainFallbackCaretaker, setRemainFallbackCaretaker] = useState(false);

  // Destructure pet data
  const { pet_passport_id, metadata, pet_owner_id, status } = pet;
  const { title, description, media } = metadata;
  const { species, gender, breed, 'life-stage': lifeStage } = JSON.parse(description);
  // const userID = user.publicAddress;

  const NewUserSchema = Yup.object().shape({
    fallback: Yup.boolean(),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));

      console.log(data);

      setNewCaretakerEmail(data.email);
      setNewCaretakerPhone(data.phoneNumber);
      setNewCaretakerZip(data.zipCode);
      setIsTrialPeriod(data.trial);
      setRemainFallbackCaretaker(data.fallback);
      handleNextPage();

      // reset();
      //     enqueueSnackbar(currentUser ? 'Update success!' : 'Create success!');
      //     router.push(paths.dashboard.user.list);
      //     console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const handleNextPage = () => {
    // if (page === 1) {
    //   if (!newCaretakerEmail) {
    //     alert('Please fill out all fields.');
    //     return;
    //   }
    // }
    setPage(page + 1);
  };

  const handleUpdatePetStatus = async () => {
    // Prepare the data to send in the API request
    const requestData = {
      pet_passport_id,
      account_id: pet_owner_id, // Assuming user is the currently logged-in user
      new_status, // Replace with the desired status
    };

    try {
      // Make the API request to update the pet's status using updatePetStatus function
      const updateStatusResponse = await updatePetStatus(pet_passport_id, pet_owner_id, new_status);
      console.log('response: ', updateStatusResponse.success);

      if (updateStatusResponse.success) {
        // Call the callback function to update petStatus when updateStatusResponse is true
        callback(new_status);
        setPage(3);
      }

      // if (!updateStatusResponse.success) {
      //   // Handle API request error here
      //   console.error('API request error:', updateStatusResponse.message);
      //   return;
      // }

      // // If the API request was successful, proceed to send the email
      // // Send email using sendEmail function (replace with your service and template details)
      // const userEmail = newCaretakerEmail; // Replace with the user's email address
      // const emailResponse = await sendEmail(userEmail);

      // if (!emailResponse.success) {
      //   // Handle email sending error here
      //   console.error('Email sending error:', emailResponse.message);
      //   return;
      // }

      // console.log('Pet status updated and email sent successfully');
      // setPage(3); // Move to the "All Done" page after sending the email
    } catch (error) {
      // Handle any other errors here
      console.error('An error occurred:', error);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle>{page === 1 ? 'Edit ' : page === 2 ? 'Review' : 'All Done'}</DialogTitle>
      {page === 1 && (
        <Stack spacing={2}>
          <DialogContent sx={{ overflow: 'unset' }}>
            <Stack direction="column" spacing={2} sx={{ mb: 3 }}>
              <Typography style={{ fontWeight: 'bold' }}>I want to change the status of:</Typography>

              <ListItemText
                primary={`${title} the ${species.split(':').pop()}`}
                // secondary={` an ${breed}`}
                secondaryTypographyProps={{ component: 'span', mt: 0.5 }}
                sx={{ paddingLeft: '16px' }}
              />
            </Stack>

            <Stack spacing={3}>
              <Typography style={{ fontWeight: 'bold' }}>To the following human: </Typography>

              <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack spacing={3}>
                  <RHFSwitch
                    name="fallback"
                    labelPlacement="start"
                    label={
                      <>
                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                          Toggle
                        </Typography>
                      </>
                    }
                    sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                  />

                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      Save Changes
                    </LoadingButton>
                  </Stack>
                </Stack>
              </FormProvider>
            </Stack>
          </DialogContent>
        </Stack>
      )}
      {page === 2 && (
        <DialogContent>
          <Stack spacing={3} sx={{ px: 3 }}>
            <Typography variant="body1">
              Remain as Fallback Caregiver: {remainFallbackCaretaker ? 'Yes' : 'No'}
            </Typography>
          </Stack>
        </DialogContent>
      )}
      {page === 3 && (
        <Stack spacing={3} sx={{ px: 3 }}>
          <Typography variant="body1">Transfer successfully made!</Typography>
        </Stack>
      )}
      <DialogActions>
        {page === 2 && (
          <>
            <Button onClick={() => setPage(page - 1)}>Back</Button>
            <Button variant="contained" onClick={handleUpdatePetStatus}>
              Confirm Transfer
            </Button>
          </>
        )}
        {page === 3 && <Button onClick={onClose}>Close</Button>}
      </DialogActions>
    </Dialog>
  );
}
