import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PetCard from './PetCard';

PetList.propTypes = {
  apiPets: PropTypes.array.isRequired, // Rename the prop to apiPets
};

export default function PetList({ user, apiPets, updateFilteredAndSortedPets, ...other }) {

  // Check if apiPets is undefined or not an array
  if (!Array.isArray(apiPets) || apiPets.length === 0) {
    return <div>No pets to display</div>;
  }

  // Map apiPets to the format expected by PetCard
  const mappedPets = apiPets.map((apiPet) => ({
    pet_passport_id: apiPet.pet_passport_id,
    metadata: {
      title: apiPet.name,
      description: JSON.stringify({
        species: apiPet.type,
        breed: apiPet.breed,
        gender: apiPet.gender,
        "life-stage": apiPet.age?.life_stage, // Use optional chaining here
        dob: apiPet.age?.dob, // Use optional chaining here
      }),
      media: apiPet.avatar_file_name,
    },
    pet_owner_id: apiPet.current_owner_description?.owner_id, // Use optional chaining here
    status: apiPet.status,
  }));

  return (
    <Grid container spacing={3} {...other}>
      {mappedPets.map((pet_data) => (
        <Grid key={pet_data.pet_passport_id} item xs={12} sm={6} md={4}>
          <PetCard
            user={user}
            pet={pet_data}
            filteredAndSortedPets={apiPets}
            updateFilteredAndSortedPets={updateFilteredAndSortedPets}
          />
        </Grid>
      ))}
    </Grid>
  );
}

